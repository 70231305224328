import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const apiURLs = {
  root: environment.restApiUrl,
  referral: '/client/referral/',
  SFlead: '/salesforce/lead/',
}

export interface ReferralSalesforce {
  referral_first_name: string;
  referral_last_name: string;
  referral_email: string;
}

export interface ReferralData {
  contact_date: string;
  contact_time: string;
  contact_method: string;
  name: string;
  email: string;
  phone_number: string;
  current_provider: string;
  umbrella_plc: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  constructor(
    private http: HttpClient,
  ) { }

  sendReferral(data: ReferralSalesforce): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.SFlead, data);
  }

  sendReferralEmail(data: ReferralData): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.referral, data);
  }

}
